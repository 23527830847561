import React, { FC } from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const SecondPage: FC = () => (
  <Layout>
    <SEO title="Page two" />
    <h1>Hi this is the second page</h1>
    <p>Gatsby is fun.</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default SecondPage;
